<template>
  <div>
    <v-row class="fill-height breadcrumb pt-4" justify="center" align="center">
      <h1 class="font-weight-bold text-h3 white--text">IT / SIWES</h1>
    </v-row>
    <v-container>
      <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>

      <v-card class="mt-7">
        <v-card-title class="mt-n4">
          <h3>IT/SIWES REGISTRATION</h3>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="dform" lazy-validation @submit.prevent>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="dform.fullname"
                    :rules="nameRules"
                    hint="Surname First"
                    persistent-hint
                    required
                  >
                    <template #label>
                      Full Name<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="dform.email"
                    :rules="emailRules"
                    required
                  >
                    <template #label>
                      Email<span class="error--text">*</span>
                    </template></v-text-field
                  >
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model.trim="dform.dob" type="date" required>
                    <template #label>
                      Date of Birth<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="dform.phone"
                    :rules="phoneRules"
                    maxlength="11"
                    counter="11"
                    required
                  >
                    <template #label>
                      Mobile Number<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model.trim="dform.religion"
                    :items="religions"
                    :rules="messageRules"
                    required
                  >
                    <template #label>
                      Religion<span class="error--text">*</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="dform.gender"
                    :items="genders"
                    :rules="messageRules"
                    required
                  >
                    <template #label>
                      Gender<span class="error--text">*</span>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model.trim="dform.address"
                    :rules="messageRules"
                  >
                    <template #label>
                      Address<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model.trim="dform.school"
                    :rules="messageRules"
                  >
                    <template #label>
                      Name of Institution<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="dform.discipline"
                    :rules="messageRules"
                  >
                    <template #label>
                      Course of study<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model.trim="dform.degree"
                    label="Degree in view*"
                    placeholder="Bsc"
                    :rules="messageRules"
                  >
                    <template #label>
                      Degree in view<span class="error--text">*</span>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <b class="text-center">
            <span class="error--text">*</span> indicates required fields</b
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="ma-4"
            :loading="loading"
            color="info"
            @click="submitForm"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-snackbar v-model="snackbar" :color="snackColor" bottom :timeout="5000">
        {{ snackMsg }}

        <template v-slot:action="{ attrs }">
          <v-btn color="dark" v-bind="attrs" @click="snackbar = false">
            <v-icon>mdi-close</v-icon>
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { apiClient } from "@/services";
import { snackMixin, rulesMixin } from "@/mixins";
export default {
  name: "Siwes",
  mixins: [snackMixin, rulesMixin],
  data() {
    return {
      dform: this.initializeForm(),
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          to: { name: "Home" },
        },
        {
          text: "IT / SIWES",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    ...mapState(["religions", "genders"]),
  },

  methods: {
    submitForm() {
      if (!this.$refs.dform.validate()) {
        return;
      }
      this.loading = true;
      apiClient
        .post("/it-students", this.dform)
        .then((res) => {
          if (res.data.ok) {
            this.dform = this.initializeForm();
            this.displayMsg("Registration has been submitted successfully.");
            this.$refs.dform.resetValidation();
          }
        })
        .catch((error) => {
          this.displayMsg(error.response.data.errorMessage, "error");
        });
    },

    initializeForm() {
      return {
        fullname: "",
        email: "",
        phone: "",
        address: "",
        dob: "",
        religion: "",
        gender: "",
        school: "",
        degree: "",
        discipline: "",
      };
    },
  },
};
</script>
